.divMapa {
margin: 0 auto;
height: 200px;
width: 300px;
box-sizing: border-box;
}

.leaflet-container {
    height: 200px;
    width: 300px;
    border-radius: 15px;
    margin: 0 auto;
}

.leaflet-container.dash {
    height: 300px;
    width: 500px;
    border-radius: 15px;
    margin: 0 auto;
}